import { getText, appendText } from "../../assets/js/text-renderer";
import { setSharedCtaContent } from "../shared/shared-cta.js";

//Web components
import "bootstrap/js/dist/carousel";
import "../shared/sectores";
import "../shared/news-card";
import "../shared/cta/cta";

//Styles
import "./home.scss";
import "swiffy-slider/css";

window.swiffyslider = swiffyslider;

window.addEventListener("load", () => {
  window.swiffyslider.init();
});

document.addEventListener("DOMContentLoaded", async () => {
  if (
    DEVELOPMENT || (
    window["__PRERENDER_INJECTED"] &&
    window["__PRERENDER_INJECTED"].render_text)
  ) await loadText();
});

const loadText = async () => {
  const homeData = await getText("home")
  const keyData = await getText('shared/keywords');

  const homeContent = homeData.stories[0].content;
  const keyContent = keyData.stories[0].content;

  setHeaderContent(homeContent);
  setServicesContent(homeContent);
  setAiContent(homeContent);
  setPlatformContent(homeContent);
  setSmartBiContent(homeContent);
  setClientsContent(homeContent);
  setNewsContent(keyContent);
  await setSharedCtaContent();

  window.dispatchEvent(new CustomEvent("rendered-text", { detail: "content" }));
  window.dispatchEvent(new CustomEvent("rendered-text", { detail: "keywords" }));
}

function setHeaderContent(content) {
  appendText(content.header_title, "header-title");
  appendText(content.header_description, "header-description");
  appendText(content.header_button, "header-button");
  appendText(content.intro_title, "intro-title");
  appendText(content.intro_description, "intro-description");
}

function setServicesContent(content) {
  appendText(content.services[0].title, "services-0-title");
  appendText(content.services[0].subtitle, "services-0-subtitle");
  appendText(content.services[0].content, "services-0-content");
  appendText(content.services[0].text, "services-0-button");

  appendText(content.services[1].title, "services-1-title");
  appendText(content.services[1].subtitle, "services-1-subtitle");
  appendText(content.services[1].content, "services-1-content");
  appendText(content.services[1].text, "services-1-button");

  appendText(content.services[2].title, "services-2-title");
  appendText(content.services[2].subtitle, "services-2-subtitle");
  appendText(content.services[2].content, "services-2-content");
  appendText(content.services[2].text, "services-2-button");

  appendText(content.services[3].title, "services-3-title");
  appendText(content.services[3].subtitle, "services-3-subtitle");
  appendText(content.services[3].content, "services-3-content");
  appendText(content.services[3].text, "services-3-button");
}

function setAiContent(content) {
  appendText(content.ai[0].title, "ai-title");
  appendText(content.ai[0].subtitle, "ai-subtitle");
  appendText(content.ai[0].content, "ai-text");
  appendText(content.ai[0].text, "ai-button");
}

function setPlatformContent(content) {
  appendText(content.platform_title, "platform-title");
  appendText(content.platform_intro, "platform-intro");
}

function setSmartBiContent(content) {
  appendText(content.smart_bi[0].title, "smartbi-title");
  appendText(content.smart_bi[0].richtext, "smartbi-text");
  appendText(content.smart_bi[0].text, "smartbi-button");
}

function setClientsContent(content) {
  appendText(content.our_clients, "empresas-text");
}

function setNewsContent(content) {
  appendText(content.noticias, "news-title");
  appendText(content.noticias_hablan, "news-subtitle");
}