import html from './sectores.html';
import { getText, appendText } from '../../assets/js/text-renderer';
// Instead of bootstrap carousel we could add https://github.com/sciactive/tinygesture to our CSS carousel.
import 'bootstrap/js/dist/carousel';
import './sectores.scss';
import 'swiffy-slider/css';
// import './news-slider.scss';
import { swiffyslider } from 'swiffy-slider';
window.swiffyslider = swiffyslider;

window.addEventListener('load', () => {
  window.swiffyslider.init();
});

// function moveCarousel(direction, position, scroller) {
//   if (direction === 'right') position++;
//   if (direction === 'left') position--;
//   scroller.style.setProperty('--position', position);
//   return position;
// }

function loadText(element) {
  return getText('shared/keywords')
    .then(function (dict) {
      const texts = dict.stories[0].content;

      // Texts for mobile version
      appendText(texts.soluciones, 'soluciones', element);
      appendText(texts.por_sectores_de_aplicacion, 'soluciones-por-sector', element);
      appendText(texts.sector_industria, 'sector-industria', element);
      appendText(texts.sector_retail, 'sector-retail', element);
      appendText(texts.sector_seguros, 'sector-seguros', element);
      appendText(texts.sector_horeca, 'sector-horeca', element);
      appendText(texts.sector_admin, 'sector-admin', element);
      appendText(texts.sector_industria_des, 'sector-industria-des', element);
      appendText(texts.sector_retail_des, 'sector-retail-des', element);
      appendText(texts.sector_seguros_des, 'sector-seguros-des', element);
      appendText(texts.sector_horeca_des, 'sector-horeca-des', element);
      appendText(texts.sector_admin_des, 'sector-admin-des', element);
    })

    .catch(function (err) {
      console.log('Fetch Error :-S', err);
    });
}

class Sectores extends HTMLElement {
  constructor() {
    super();
    // this.scrollerSectoresPosition = 0;
  }

  // leftClick(event) {
  //   if (this.scrollerSectoresPosition > 0)
  //     this.scrollerSectoresPosition = moveCarousel('left', this.scrollerSectoresPosition, this.scrollerSectores);
  //   event.preventDefault();
  // }
  // rightClick(event) {
  //   if (this.scrollerSectoresPosition < 1)
  //     this.scrollerSectoresPosition = moveCarousel('right', this.scrollerSectoresPosition, this.scrollerSectores);
  //   event.preventDefault();
  // }

  connectedCallback() {
    if (DEVELOPMENT || (window['__PRERENDER_INJECTED'] && window['__PRERENDER_INJECTED'].render_text)) {
      this.innerHTML = html;
      // document.addEventListener('DOMContentLoaded', function () {
      const container = document.getElementById('sectores-container');
      loadText(container).then(() => {
        let items = container.querySelectorAll('.carousel-pc .carousel-item');

        items.forEach((el) => {
          const minPerSlide = 4;
          let next = el.nextElementSibling;
          for (var i = 1; i < minPerSlide; i++) {
            if (!next) {
              // wrap carousel by using first child
              next = items[0];
            }
            let cloneChild = next.cloneNode(true);
            el.appendChild(cloneChild.children[0]);
            next = next.nextElementSibling;
          }
        });
      });
      // });
    }

    // this.scrollerSectores = document.querySelectorAll('.horizontal-scroller.sectores')[0];
    // this.buttonLeft = document.getElementById('sectores-left');
    // this.buttonRight = document.getElementById('sectores-right');
    // this.buttonLeft.addEventListener('click', (ev) => this.leftClick(ev));
    // this.buttonRight.addEventListener('click', (ev) => this.rightClick(ev));

    // const dotsButtons = document.querySelectorAll('.sectores .dots')[0];
    // dotsButtons.addEventListener('click', (event) => {
    //   let dot = event.srcElement;
    //   const children = dotsButtons.children;
    //   for (i = 0; i < children.length; i++) {
    //     children[i].classList.remove('active');
    //   }
    //   dot.classList.add('active');

    //   let i = 0;
    //   while ((dot = dot.previousElementSibling) != null) i++;
    //   this.scrollerSectores.style.setProperty('--position', i);
    //   event.preventDefault();
    // });
  }

  disconnectedCallback() {
    // this.buttonLeft.removeEventListener('click', () => this.leftClick());
    // this.buttonRight.removeEventListener('click', () => this.rightClick());
  }
}

customElements.define('custom-sectores', Sectores);
