import "./news-card.scss";

const newCard = (data) => `
  ${data.href 
    ? `  
    <a href="${data.href}" target="_blank">
      ${getCardContent(data)}
    </a>`
    : `
    <article>
      ${getCardContent(data)}
    </article>`
  }
`;

const getCardContent = (data) => (`
  <div>
    <img 
      src="${data.img}" 
      loading="lazy" 
      alt="News Image Logo" 
    />
    <h5 class="lh-sm">${data.title}</h5>
    ${data.content 
      ? `<span>${data.content}</span>`
      : ""
    }
  </div>
  ${data.footer 
    ? `<span>${data.footer}</span>`
    : ""
  }
`);

/**
 *
 */
class News extends HTMLElement {
  connectedCallback() {
    this.render();
  }
  
  render() {
    const cardData = this.getCardData()
    this.innerHTML = newCard(cardData);
  } 

  getCardData() {
    return {
      content: this.getAttribute("data-content"),
      footer: this.getAttribute("data-footer"),
      href: this.getAttribute("data-href"),
      img: this.getAttribute("data-img"),
      title: this.getAttribute("data-title") || "Title",
    };
  }
}

customElements.define("custom-news", News);
