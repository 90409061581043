import html from "./cta.html";
import "./cta.scss";

class CtaCurve extends HTMLElement {
  connectedCallback() {
    if( DEVELOPMENT || (window["__PRERENDER_INJECTED"] && window["__PRERENDER_INJECTED"].render_text) ) {
      this.innerHTML = html;
      this.updateTitles();
      this.updateButtons();

      window.dispatchEvent(
        new CustomEvent("rendered-text", { detail: "cta" })
      );
    }
  }

  updateTitles() {
    // Get attributes
    const title = this.getAttribute("title") || "Default Title";
    const description = this.getAttribute("description");

    // Get elements to update
    const titleElement = this.querySelector("#cta-title");
    const descriptionElement = this.querySelector("#cta-description");

    // Update elements with attributes
    if (titleElement) titleElement.textContent = title;
    if (descriptionElement) {
      description
        ? (descriptionElement.textContent = description)
        : descriptionElement.remove();
    }
  }

  updateButtons() {
    // Get attributes
    const primaryButtonUrl = this.getAttribute("primary-button-url") || "#";
    const primaryButtonText = this.getAttribute("primary-button-text");
    const secondaryButtonUrl = this.getAttribute("secondary-button-url") || "#";
    const secondaryButtonText = this.getAttribute("secondary-button-text");

    // Get elements to update
    const primaryButtonElement = this.querySelector("#cta-primary-button");
    const secondaryButtonElement = this.querySelector("#cta-secondary-button");
    const ctaButtonsContainer = this.querySelector("#cta-buttons");

    // Update elements with attributes
    if (primaryButtonText) {
      primaryButtonElement.textContent = primaryButtonText;
      primaryButtonElement.href = primaryButtonUrl;
    } else {
      primaryButtonElement.remove();
    }

    if (secondaryButtonText) {
      secondaryButtonElement.textContent = secondaryButtonText;
      secondaryButtonElement.href = secondaryButtonUrl;
    } else {
      secondaryButtonElement.remove();
    }

    if (!primaryButtonText && !secondaryButtonText) {
      ctaButtonsContainer.remove();
    }
  }
}

customElements.define("custom-cta", CtaCurve);
