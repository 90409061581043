
import { getText } from "../../assets/js/text-renderer";

export const setSharedCtaContent = async () => {
  const data = await getText("shared/shared-cta");
  const content = data.stories[0].content;

  const customCtaContainer = document.getElementById('shared-cta');
  const customCtaEl = document.createElement('custom-cta');
  customCtaEl.setAttribute('title', content.title);
  customCtaEl.setAttribute('description', content.description);
  customCtaEl.setAttribute('primary-button-text', content.btn_primary);
  customCtaEl.setAttribute('primary-button-url', '/nosotros/contact/');
  customCtaContainer.appendChild(customCtaEl);

  window.dispatchEvent(new CustomEvent("rendered-text", { detail: "cta" }));
}